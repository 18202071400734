footer {
  background: url("https://kkportfolio-assets.s3.ap-south-1.amazonaws.com/images/bg2.jpg");
  background-size: cover;
  background-attachment: fixed;
  color: #fff;
  padding: 80px 0;
  text-align: center;
}

.copyright {
  font-size: 15px;
  margin-bottom: 20px;
}

.copyright a {
  font-size: 16px;
  color: #48dbfb;
  font-weight: 500;
}

.goTop {
  position: fixed;
  z-index: 999;
  bottom: 40px;
  right: 40px;
  width: 40px;
  height: 50px;
  background-color: #48dbfb;
  color: #fff;
  border: none;
  border-radius: 200px;
  cursor: pointer;
  outline: none;
  display: none;
}
