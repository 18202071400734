section {
  padding: 100px 0;
  background-color: #f1f1f1;
}

.title {
  text-align: center;
  position: relative;
  font-size: 20px;
  color: #000;
  font-weight: 500;
}

.section-title {
  text-align: center;
  position: relative;
  padding-bottom: 20px;
  margin-top: 20px;
  margin-bottom: 80px;
  color: #000;
}

.section-title::before {
  width: 80px;
  content: "";
  position: absolute;
  height: 2px;
  background-color: #000;
  bottom: 1;
  left: calc(50% - 40px);
}

.section-title::after {
  content: "";
  position: absolute;
  width: 16px;
  height: 10px;
  background-color: #48dbfb;
  border: 4px solid #f1f1f1;
  left: calc(50% - 12px);
  margin-top: -7px;
}

section.dark {
  background-color: #353b48;
}

section.dark .title {
  color: #f1f1f1;
}

section.dark .section-title::before {
  position: absolute;
  background-color: #f1f1f1;
  bottom: 1;
  left: calc(50% - 60px);
  width: 120px;
}

section.dark .section-title::after {
  border: 4px solid #353b48;
}
