.about-content{
    display: flex;
    align-items: center;
    flex-wrap: wrap;
}

.about-pic{
    width:300px;
    border-radius: 100%;
    margin-right: 100px;
}

.about-text{

    flex:1;
}
.about-text h3{

    margin:5px 0;
    color: #444;
    font-size: 16px;
}

.about-text h3 span:nth-child(1):after,
.about-text h3 span:nth-child(2):after{
    content:"";
    height:6px;
    width:6px;
    background-color:#444;
    display: inline-block ;
    border-radius: 50%;
    margin: 0 14px;
}

.about-text p{

    font-size: 15px;
    text-align: justify;
    line-height: 25px;
    margin-top: 20px;
}

.skills{
    margin-top: 40px;
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
}

.skill{

    width: calc(50% - 20px);
    margin: 15px 0;
}

.skill-info{

    display: flex;
    justify-content: space-between;
}