.contact-info {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  margin-bottom: 50px;
}

.contact-info .item {
  width: calc(33% - 10px);
  height: 150px;
  border-radius: 50px;
  background-color: #353b48;
  color: #fff;
  text-align: center;
  cursor: pointer;
  transition: 0.3s linear;
}

.contact-info i {
  display: block;
  font-size: 40px;
  line-height: 120px;
  height: 100px;
}

.contact-info .item:hover {
  background-color: #48dbfb;
}

.contact-form {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  margin-bottom: 30px;
}

.contact-form input,
.contact-form textarea {
  width: 100%;
  height: 50px;
  background-color: #353b48;
  margin: 10px 0;
  border: none;
  outline: none;
  padding: 20px;
  border-radius: 50px;
  color: #fff;
  margin-bottom: 10px;
}

.name,
.email {
  max-width: calc(50% - 10px);
}

.message {
  height: 200px;
  resize: vertical;
}

.contact-form .btn {
  width: 150px;
  border: 2px solid #48dbfb;
  padding: 5px;
  margin-left: auto;
  cursor: pointer;
  background-color: transparent;
  border-radius: 120px;
  color: #48dbfb;
  font-size: 30px;
  transition: 0.2s linear;
}

.contact-form .btn:hover {
  color: #fff;
  background-color: #48dbfb;
}

.successMessage {
  color: green;
}

.failedMessage {
  color: red;
}

.formNote {
  color: #353b48;
}
