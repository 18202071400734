.inner-width{
    max-width: 1300px;
    margin: auto;
    padding: 0 40px;
}

.logo{
    width: 44px;
    height: 32px;
    background-size: contain;
}

$primary-color: #48dbfb;