.navbar{
  
    position: fixed;
    background-color: transparent;
    width: 100%;
    padding: 30px 0;
    top: 0;
    z-index: 999;
    transition: .3s linear;

}

.navbar .inner-width{
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.menu-toggler{
    background: none;
    width: 30px;
    border: none;
    cursor: pointer;
    position: relative;
    outline: none;
    z-index: 999;
    display: none;
}

.logo{
  width: 44px;
  height: 32px;
  background: url('https://kkportfolio-assets.s3.ap-south-1.amazonaws.com/assets/logo/white-logo.png');
  background-size: contain;
}

  .menu-toggler span{

    display: block;
    height: 3px;
    background-color: #fff;
    margin: 6px 0;
    position: relative;
    transition: .3s linear;
}

.navbar-menu a{

    color: white;
    font-size: 15px;
    font-weight: 500;
    margin-left: 30px;
    transition: .2s linear;

  }

  .navbar-menu a:hover{
      color: #48dbfb !important;
}

  .sticky{

       background-color: #fff;
       padding: 18px 0;
}

  .sticky .logo{

    background-image: url('https://kkportfolio-assets.s3.ap-south-1.amazonaws.com/assets/logo/black-logo.png');
}

  .sticky .navbar-menu a{
      color: #111;
}

  .sticky .menu-toggler span{
      background-color: #111;
}