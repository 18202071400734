.blockcertif {
  width: calc(50% - 20px);
  background-color: #fff;
  border: 1px solid #ddd;
  border-radius: 50px;
  margin: 10px 0;
  padding: 30px;
  position: relative;
  text-align: justify;
  transition: 0.4s ease-in-out;
}

.blockcertif:hover {
  box-shadow: 0 5px rgba(97, 92, 92, 0.774);
}

.blockcertif h3 {
  font-size: 15px;
  vertical-align: auto;
  padding-bottom: 5px;
}
.info1 .info2 {
  position: absolute;
  z-index: 2;
  bottom: 120px;
  left: 40px;
  color: #353b48;
  transition: 0.4s linear;
  opacity: 0;
}

.info1::before {
  content: "";
  position: absolute;
  z-index: 1;
  background-color: #48dbfbcc;
  transition: 0.2s linear;
  transform: scale(0);
}

.info1 {
  border-radius: 50%;
  padding-bottom: 10px;
  cursor: pointer;
  transition: 0.4s linear;
  border: none;
}
.info1:hover img {
  transform: scale(0.6) rotate(25deg);
  opacity: 0.5;
}

.info1:hover::before,
.info1:hover .info2 {
  opacity: 1;
  transform: scale(1);
  border: none;
}

.blockcertif p {
  color: #444;
  font-size: 13px;
}

.viewButton {
  border: none;
  cursor: pointer;
}

.certifModal {
  width: 740px;
  height: 550px;
  padding: 20px;
  background: #232731;
  border: none;
  display: flex;
  align-content: center;
  color: #ddd;
}

.certifPic {
  align-content: center;
  width: 700px;
  height: 500px;
}

.icon {
  width: 70px;
  width: 70px;
  border-radius: 10px;
}
