@media screen and (max-width: 980px) {
  .menu-toggler {
    display: block;
  }

  .navbar-menu {
    position: fixed;
    height: 100vh;
    widows: 100%;
    background-color: #353b48;
    top: 0;
    right: -100%;
    max-width: 400px;
    padding: 80px 50px;
    transition: 0.3s linear;
  }

  .navbar-menu a {
    display: block;
    font-size: 30px;
    margin: 30px 0;
  }

  .sticky .navbar-menu {
    background-color: #f1f1f1;
  }

  .navbar-menu.active {
    right: 0;
  }

  .menu-toggler.active span:nth-child(1) {
    transform: rotate(-45deg);
    top: 4px;
  }

  .menu-toggler.active span:nth-child(2) {
    opacity: 0;
  }
  .menu-toggler.active span:nth-child(3) {
    transform: rotate(45deg);
    bottom: 14px;
  }

  .inner-width {
    max-width: 800px;
  }

  .about-pic {
    margin: 0 auto 60px;
  }

  .about-text {
    flex: 100%;
    text-align: center;
  }

  .service {
    width: calc(17% - 10px);
  }
  .block {
    width: calc(100% - 20px);
    margin-left: auto;
  }

  .blockmid {
    width: calc(100% - 20px);
    margin-left: auto;
  }

  .blockcertif {
    width: calc(100% - 20px);
    margin-left: auto;
  }

  .certifModal {
    width: 350px;
    height: 250px;
  }

  .certifPic {
    width: 300px;
    height: 220px;
  }

  .experience {
    width: 100%;
  }

  .project {
    width: 100%;
  }
}

@media screen and (max-width: 600px) {
  .inner-width {
    padding: 0 20px;
  }

  .service {
    width: 22%;
  }

  .experience {
    width: 100%;
  }

  .project {
    width: 100%;
  }
  .proj-link {
    width: 100%;
  }

  .certifModal {
    width: 350px;
    height: 250px;
  }

  .certifPic {
    width: 300px;
    height: 220px;
  }
}
